<template>
  <div class="GlobalTable">
    <template v-if="Array.isArray(seachDataList) && seachDataList.length > 0">
      <template>
        <GlobalTable v-if="accountType == 'merchant'" ref="merchantTable" v-loading="loading" :columns="tableColumns1"
          :data="this.seachDataList" :currentPage="page.pageNum" :total="page.total"
          @handleCurrentChange="handleCurrentChange">
          <el-table-column label="账单流水号" slot="uniqNo" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.serType == '18'" style="color: red;">{{ row.uniqNo || "--" }}</span>
              <span v-else>{{ row.uniqNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="原始订单号" slot="orderNo" align="center">
            <template slot-scope="{ row }">
              <div style="color: #0981ff;cursor: pointer;" v-if="row.orderChannel == 'recycle'"
                @click="toOrderDetails(row)">
                <span style="border-bottom: 1px solid #0981ff">{{ row.orderNo }}</span>
              </div>
              <span v-else>{{ row.orderNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="回收商名称" slot="merchantName" align="center">
            <template slot-scope="{ row }">
              <div >{{ row.merchantName || "--" }}</div>
              <div class="isdeal1" v-if="row.isOutMerchant">外部</div>
            </template>
          </el-table-column>
          <el-table-column label="账单类型" slot="opt" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.opt == '01' ? '入账' : row.opt == '02' ? '出账' : '挂账' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="交易门店商家" slot="companyName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.companyName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="交易门店" slot="storeName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.storeName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="accountType == 'merchant'" label="交易回收商" slot="otherMerchantName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.otherMerchantName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" slot="amount" align="center">
            <template slot-scope="{ row }">
              <span :style="row.opt == '01' ? 'color:green' : 'color:red'" v-if="row.opt != '03'">{{ row.amount >= 0 &&
                row.opt ==
                '01' ? `+${row.amount}` : `-${row.amount}` }}</span>
              <span :style="row.serType == '15' || row.serType == '23' ? 'color:green' : 'color:red'" v-else>{{ row.amount
                &&
                (row.serType == '15' || row.serType == '23') ? `+${row.amount}` : `-${row.amount}` }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
        label="当时账户余额"
        slot="afterBalance"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{row.afterBalance || 0}}</span>
        </template>
      </el-table-column> -->
          <el-table-column label="当前余额" slot="nowBalance" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.nowBalance || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="账单备注" width="130rpx" slot="remark" align="center">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
                <div class="Remarks">
                  {{ row.remark }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </GlobalTable>
        <GlobalTable v-else-if="accountType == 'company'" ref="companyTable" v-loading="loading" :columns="tableColumns2"
          :data="this.seachDataList" :currentPage="page.pageNum" :total="page.total"
          @handleCurrentChange="handleCurrentChange">
          <el-table-column label="账单流水号" slot="uniqNo" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.serType == '62'" style="color: red;">{{ row.uniqNo || "--" }}</span>
              <span v-else>{{ row.uniqNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="原始订单号" slot="orderNo" align="center">
            <template slot-scope="{ row }">
              <div style="color: #0981ff;cursor: pointer;" v-if="row.orderChannel == 'recycle'"
                @click="toOrderDetails(row)">
                <span style="border-bottom: 1px solid #0981ff">{{ row.orderNo }}</span>
              </div>
              <span v-else>{{ row.orderNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="外部订单号" slot="outOrderNo" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.outOrderNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="账单类型" slot="opt" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.opt == '01' ? '入账' : row.opt == '02' ? '出账' : '挂账' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="交易回收商家" slot="merchantName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.merchantName || "--" }}</span>
              <div v-if="row.merchantNice">
                <el-tag type="warning" effect="dark" size="mini">靓机回收商</el-tag>
              </div>
              <div class="isdeal1" v-if="row.isOutMerchant">外部</div>
            </template>
          </el-table-column>
          <el-table-column label="交易门店" slot="storeName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.storeName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额" slot="amount" align="center">
            <template slot-scope="{ row }">
              <span :style="row.opt == '01' ? 'color:green' : 'color:red'" v-if="row.opt != '03'">{{ row.amount >= 0 &&
                row.opt ==
                '01' ? `+${row.amount}` : `-${row.amount}` }}</span>
              <span :style="row.serType == '15' || row.serType == '23' ? 'color:green' : 'color:red'" v-else>{{ row.amount
                &&
                (row.serType == '15' || row.serType == '23') ? `+${row.amount}` : `-${row.amount}` }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
        label="当时账户余额"
        slot="afterBalance"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>{{row.afterBalance || 0}}</span>
        </template>
      </el-table-column> -->
          <el-table-column label="外部成交价" slot="outFinalPrice" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.outFinalPrice || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="当前余额" slot="nowBalance" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.nowBalance || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="账单备注" width="130rpx" slot="remark" align="center">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
                <div class="Remarks">
                  {{ row.remark }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </GlobalTable>
        <GlobalTable v-else-if="accountType == 'companyActivity'" ref="companyactivityTable" v-loading="loading" :columns="tableColumnsactive"
          :data="seachDataList" :currentPage="page.pageNum" :total="page.total"
          @handleCurrentChange="handleCurrentChange">
          <el-table-column label="账单流水号" slot="uniqNo" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.serType == '62'" style="color: red;">{{ row.uniqNo || "--" }}</span>
              <span v-else>{{ row.uniqNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="原始订单号" slot="orderNo" align="center">
            <template slot-scope="{ row }">
              <div style="color: #0981ff;cursor: pointer;" v-if="row.orderChannel == 'recycle'"
                @click="toOrderDetails(row)">
                <span style="border-bottom: 1px solid #0981ff">{{ row.orderNo }}</span>
              </div>
              <span v-else>{{ row.orderNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="账单类型" slot="opt" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.opt == '01' ? '入账' : row.opt == '02' ? '出账' : '挂账' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参与补贴活动" slot="renewActivityName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.renewActivityName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="门店" slot="merchantName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.storeName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="店员" slot="storeName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.companyStaffName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="交易金额" slot="amount" align="center">
            <template slot-scope="{ row }">
              <span :style="row.opt == '01' ? 'color:green' : 'color:red'" v-if="row.opt != '03'">{{ row.amount >= 0 &&
                row.opt ==
                '01' ? `+${row.amount}` : `-${row.amount}` }}</span>
              <span :style="row.serType == '15' || row.serType == '23' ? 'color:green' : 'color:red'" v-else>{{ row.amount
                &&
                (row.serType == '15' || row.serType == '23') ? `+${row.amount}` : `-${row.amount}` }}</span>
            </template>
          </el-table-column>
          <el-table-column label="当前余额" slot="nowBalance" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.nowBalance || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="账单备注" width="130rpx" slot="remark" align="center">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
                <div class="Remarks">
                  {{ row.remark }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </GlobalTable>
        <!-- 门店差异基金账单 -->
        <GlobalTable v-else-if="accountType == 'companyDiffFund'" ref="companyDiffFundTable" v-loading="loading"
          :columns="tableColumns3" :data="this.seachDataList" :currentPage="page.pageNum" :total="page.total"
          @handleCurrentChange="handleCurrentChange">
          <el-table-column label="账单流水号" slot="uniqNo" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.serType == '18'" style="color: red;">{{ row.uniqNo || "--" }}</span>
              <span v-else>{{ row.uniqNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="关联回收单号" slot="orderNo" align="center">
            <template slot-scope="{ row }">
              <div style="color: #0981ff;cursor: pointer;" v-if="row.orderChannel == 'recycle'"
                @click="toOrderDetails(row)">
                <span style="border-bottom: 1px solid #0981ff">{{ row.orderNo }}</span>
              </div>
              <span v-else>{{ row.orderNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="门店商家名称" slot="companyName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.companyName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="门店" slot="storeName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.storeName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="账单类型" slot="opt" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.opt == '01' ? '入账' : row.opt == '02' ? '出账' : '挂账' }}</span>
            </template>
          </el-table-column>
          <!-- 交易商家 -->
          <el-table-column label="交易商家" slot="merchantName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.merchantName || "--" }}</span>
              <div v-if="row.merchantNice">
                <el-tag type="warning" effect="dark" size="mini">靓机回收商</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="金额" slot="amount" align="center">
            <template slot-scope="{ row }">
              <span :style="row.opt == '01' ? 'color:green' : 'color:red'" v-if="row.opt != '03'">{{ row.amount >= 0 &&
                row.opt ==
                '01' ? `+${row.amount}` : `-${row.amount}` }}</span>
              <span :style="row.serType == '15' || row.serType == '23' ? 'color:green' : 'color:red'" v-else>{{ row.amount
                &&
                (row.serType == '15' || row.serType == '23') ? `+${row.amount}` : `-${row.amount}` }}</span>
            </template>
          </el-table-column>
          <el-table-column label="当前差异基金余额" slot="nowBalance" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.nowBalance || "--" }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="上下帐凭证" slot="nowImg" align="center">
            <template slot-scope="{ row }">
              <img style="width: 100px;height: 130px;" :src="row.voucher" @click="seeSampleImage(row.voucher)" alt="">
            </template>
          </el-table-column> -->
          <el-table-column label="备注" width="130rpx" slot="remark" align="center">
            <template slot-scope="{ row }">
              <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
                <div class="Remarks">
                  {{ row.remark }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </GlobalTable>
      </template>
    </template>
    <template v-else>
      <div v-loading="searchTabLoading" v-if="seachDataList.length <= 0"
        style="width: 100%; text-align: center; padding: 80px 0px; font-size: 24px; color: rgb(102, 102, 102); background: white; font-weight: bold;">
        暂无数据~
      </div>
    </template>
    <!-- 查看图片 -->
    <el-image-viewer
      :zIndex="99999"
      v-if="showViewer"
      :on-close="
        () => {
          imgList = [];
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import _api from "@/utils/request";
export default {
  name: "Table",
  components: { ElImageViewer },
  props: {
    accountType: {
      require: true,
      type: String
    },
  },
  data() {
    return {
      searchTabLoading:true,
      loading: false,
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10
      },
      showViewer: false,
      imgList: [],
      pageNum: 1,
      tableColumns1: [
        { slotName: "uniqNo" },
        { slotName: "orderNo" },
        // { label: "回收商名称", prop: "merchantName" },
        { slotName: "merchantName" },//回收商名称
        { label: "账单时间", prop: "createTime" },
        { slotName: "opt" },
        { label: "子类型", prop: "title" },
        { slotName: "companyName" },
        { slotName: "storeName" },
        { slotName: "otherMerchantName" },
        { slotName: "amount" },
        { slotName: "nowBalance" },
        { slotName: "remark" }
      ],
      tableColumnsactive:[
        { slotName: "uniqNo" },
        { slotName: "orderNo" },
        { label: "门店商名称", prop: "companyName" },
        { label: "账单时间", prop: "createTime" },
        { slotName: "opt" },
        { label: "子类型", prop: "title" },
        {slotName:"renewActivityName"},//焕新活动名称
        { slotName: "merchantName" },
        { slotName: "storeName" },
        { slotName: "amount" },
        { slotName: "nowBalance" },
        { slotName: "remark" }
      ],
      tableColumns2: [
        { slotName: "uniqNo" },
        { slotName: "orderNo" },
        { slotName: "outOrderNo" },
        { label: "门店商名称", prop: "companyName" },
        { label: "账单时间", prop: "createTime" },
        { slotName: "opt" },
        { label: "子类型", prop: "title" },
        { slotName: "merchantName" },
        { slotName: "storeName" },
        { slotName: "amount" },
        // { slotName: "afterBalance" },
        { slotName: "outFinalPrice" },
        { slotName: "nowBalance" },
        { slotName: "remark" }
      ],
      tableColumns3: [//门店差异基金账单
        { slotName: "uniqNo" },
        { slotName: "orderNo" },//关联回收单号
        { label: "账单时间", prop: "createTime" },
        { slotName: "companyName" },
        { slotName: "storeName" },
        { slotName: "opt" },//账单类型
        { label: "子类型", prop: "title" },
        { slotName: "merchantName" },//交易商家
        { slotName: "amount" },
        { slotName: "nowBalance" },//当前差异基金余额
        { slotName: "nowImg" },
        { slotName: "remark" }
      ],
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    },
    accountType(newVal) {
      this.searchTabLoading = true;
      this.seachDataList = [];
      console.log(newVal, 'accountType');
      this.SeachParams.opt = ""
      this.SeachParams.serType = ""
      this.handleCurrentChange()
    }
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        accountType: this.accountType,
        merchantId: this.SeachParams.merchantId,
        merchantSlaveId: this.SeachParams.merchantSlaveId,
        companyId: this.SeachParams.companyId,
        storeId: this.SeachParams.storeId,
        uniqNo: this.SeachParams.uniqNo,
        orderNo: this.SeachParams.orderNo,
        opt: this.SeachParams.opt,
        serType: this.SeachParams.serType,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      _api.getAccountPayList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.ipage.records;
          this.seachDataList.forEach(ele => {
            ele.merchantName = ele.merchantName || '--'
          });
          this.page.total = res.data.ipage.total;
          this.page.pageNum = res.data.ipage.current;
          this.loading = false
          this.searchTabLoading = false;
          this.$emit('billNumber', { companyUniqCount: res.data.companyUniqCount, merchantUniqCount: res.data.merchantUniqCount, companyDiffFundUniqCount: res.data.companyDiffFundUniqCount })
          console.log(this.seachDataList, '22222222');
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    toOrderDetails(row) {
      this.$router.push({ path: "/RecallOrder/Details", query: { type: 'edit', id: row.orderNo } })
    },
    // // 查看示例图大图
    // seeSampleImage(posterImg) {
    //   this.imgList = [];
    //   this.showViewer = true;
    //   this.imgList.push(posterImg);
    // },    
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }

  .isdeal1 {
    border:1px solid #f59a23;
    display: inline-block;
    width: max-content;
    padding: 0px 4px;
    font-size: 14px;
    margin: 0 auto;
    border-radius: 5px;
    color: #f59a23;
    text-align: center;
    margin-left: 5px;
  }
}
</style>
